import type { GetStaticProps, NextPage } from 'next'
import {
  Button,
  Headline,
  HLWMolecule,
  PageContainer,
  Pgraph,
  TopProductsMolecule,
} from '../components'
import React from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { URLS } from '../config'
import { IProductFilters, IProductSummary } from '../interfaces'
import { ProductsApi, WebConfService } from '../services'
import { INavigation } from '../interfaces/navigation.interfaces'

interface Page404Props {
  filters: IProductFilters
  topProducts: IProductSummary[]
  navigation: INavigation
}

export const getStaticProps: GetStaticProps<Page404Props> = async () => {
  const navigationJson = await WebConfService.getNavigationStructure()
  if (!navigationJson) throw new Error('Error fetching Navigation Structure')

  const filtersRes = await ProductsApi.fetchProductFilters()
  if (!filtersRes) throw new Error('Error fetching filters in 404 page')
  const filters = filtersRes?.filters

  const topProductsRes = await ProductsApi.fetchProducts({
    pageParam: 1,
    itemsPerPage: 10,
    filterOption: {
      categories: [filters.topProductsCategoryId],
    },
  })

  if (!topProductsRes)
    throw new Error('Error fetching top products in 404 page')
  const topProducts = topProductsRes.products.slice(
    0,
    parseInt(process.env.NEXT_PUBLIC_NO_OF_PRODUCTS_IN_CAROUSEL) || 9
  )

  return {
    props: {
      filters,
      topProducts,
      navigation: navigationJson,
    },
    revalidate: 300,
  }
}

const Page404: NextPage<Page404Props> = ({
  filters,
  topProducts,
  navigation,
}) => {
  const router = useRouter()
  return (
    <PageContainer
      navigation={navigation}
      seoAttributes={{
        title: 'Loop | Rent top-rated baby gear by the month',
        description:
          'A simpler way to shop for your baby: rent curated baby gear by the month.',
      }}
    >
      <></>
      <div className="flex flex-col justify-center">
        <main className="">
          <div className="max-w-6xl mx-auto pb-[0px] py-6 overflow-hidden">
            <div className="px-[160px] md:p-[0px]">
              <div className="text-center">
                <Image
                  src="/images/404.svg"
                  width="160"
                  height="160"
                  alt="Double quotes"
                />
              </div>
              <Headline
                variant="recoleta-l"
                className={`text-center mb-4 text-28px lg:text-32px mt-[40px]`}
              >
                Oops, something went wrong...
              </Headline>
              <Pgraph
                variant="p-14"
                className="text-center text-neutral-6 w-full"
              >
                This page has been removed or is temporarily unavailable
              </Pgraph>
              <div className="text-center relative my-[40px]">
                <Button
                  primary={true}
                  onClick={() => router.push(URLS.INDEX)}
                  type="button"
                  label="Go home"
                ></Button>
              </div>
            </div>
          </div>

          <HLWMolecule variant="404" />

          <TopProductsMolecule
            variant="404"
            products={topProducts}
            viewProductsUrl={`${URLS.PRODUCTS.INDEX}?categories=${filters.topProductsCategoryId}`}
          />
        </main>

        <div className="bg-heavy-cream mt-[50px] md:mt-[0px]">
          <div className="max-w-2xl mx-auto pb-[36px] pt-[40px] md:pt-[50px] md:pb-[50px] overflow-hidden">
            <div className="flex flex-col px-4 md:flex-row">
              <div className="text-center md:w-1/2 md:text-left">
                <div className="m-auto w-[315px]">
                  <Headline
                    variant="recoleta-l"
                    className="text-24px md:text-28px lg:text-32px"
                  >
                    Lost and need help?
                  </Headline>
                  <Pgraph
                    variant="p-14"
                    className="text-neutral-6 w-full mt-[6px]"
                  >
                    Send us a message, and one of our baby gear experts will get
                    back to you within 2 days.
                  </Pgraph>
                </div>
              </div>
              <div className="text-center md:w-1/2 md:text-left mt-[24px] md:mt-[0px] md:m-auto">
                <div className="text-center">
                  <Button
                    primary={false}
                    onClick={() => router.push(URLS.CONTACT_US)}
                    type="button"
                    label="Contact Us"
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default Page404
